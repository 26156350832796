<template>
  <footer class="bg-white text-center text-lg-start">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/">
        <img src="@/assets/images/im_lovin_it_with_icon.png" alt="Logo" style="width: 200px;" class="img-fluid" />
      </router-link>
      <br>
    </div>
    <div class="bg-black py-3">
      <div class="row justify-content-center">
        <div class="col-auto">
          <router-link class="font-white no-underline" to="/contact">Contact</router-link>
        </div>
        <div class="col-auto">
          <router-link class="font-white no-underline" to="/faqs">FAQ</router-link>
        </div>
      </div>
      <div class="text-justify font-size-sm">
        <div class="container">
          <div class="card bg-black">
            <div class="card-body" style="padding-left: 10%">
              Copyright© 2023 All Rights Reserved by MAKAN CERIA SDN BHD (RC/00003174) McDonald's The Golden Arches Logo
              and "I'm lovin' It" are trademarks of McDonald's Corporation and its affiliates.
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

  
<script>
export default {
  name: "FooterComponent",
};
</script>