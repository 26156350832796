
import { createRouter, createWebHistory } from 'vue-router';


const router = createRouter({
    history: createWebHistory(),
    routes: [
      {
        path: "/",
        name: "login-page",
        component: () => import('./views/web/LoginPage.vue')
      },
      {
        path: "/vouchers",
        name: "voucher",
        component: () => import('./views/web/Voucher.vue')
      },
      {
        path: "/terms-conditions",
        name: "terms-conditions-page",
        component: () => import('./views/web/TermConditions.vue')
      },
      {
        path: "/faqs",
        name: "faq-page",
        component: () => import('./views/web/Faqs.vue')
      },
      {
        path: "/contact",
        name: "contact-page",
        component: () => import('./views/web/ContactPage.vue')
      },

      {
        path: '/:catchAll(.*)',
        component: () => import('./views/web/NotFound.vue')
      }
    ]
});

export default router;