import { createApp } from "vue";
import App from "./App.vue";
import "vuetify/dist/vuetify.min.css";
import router from "./router";
import PrimeVue from "primevue/config";
import DataTable from "primevue/datatable";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "@popperjs/core";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import {
  fab,
  faFacebook,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import "primevue/resources/themes/aura-light-green/theme.css";
import "primevue/resources/primevue.min.css";
import axios from "axios";
import { global } from "./global";

axios.defaults.baseURL = global.url;

library.add(fab, fas, faFacebook, faInstagram, faTwitter);

const app = createApp(App);
app.use(router).use(PrimeVue, {
  zIndex: {
    modal: 1100, //dialog, sidebar
    overlay: 10000, //dropdown, overlaypanel
    menu: 1000, //overlay menus
    tooltip: 1100, //tooltip
  },
});

app.use(VueSweetalert2);

app.component("DataTable", DataTable);
app.component("font-awesome-icon", FontAwesomeIcon);
app.mount("#app");
