<template>
  <div class="d-flex flex-column" style="min-height: 100vh">
    <Header v-show="$route.name != 'login-page'" />
    <div class="container-fluid flex-grow-1">
      <div class="row">
        <div class="col-1">
          <SideNav class="h-100" />
        </div>
        <div class="col-11">
          <router-view />
        </div>
      </div>
    </div>
    <FooterComponent />
  </div>
</template>

<script>
import Header from "./components/TopHeader.vue";
import FooterComponent from "./components/FooterComponent.vue";
import SideNav from "./components/SideNav.vue";

export default {
  name: "App",
  components: {
    Header,
    SideNav,
    FooterComponent,
  },
};
</script>

<style>
@import "@/assets/css/main.css";

.footer {
  margin-top: auto;
}
</style>