<template>
  <div class="bg-white pt-5">
    <ul class="nav flex-column pt-5" >
      <li v-for="(nav, index) in sideNavs" :key="index" class="nav-item">
        <a class="nav-link" :href="nav.href" target="_blank">
          <font-awesome-icon :icon="nav.icon" />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SideNav",
  data() {
    return {
      sideNavs: [
        {href: 'https://goo.gl/maps/AEXe8U43wWpGevxN6', icon: ['fas', 'map-marker-alt']},
        {href: 'https://www.facebook.com/mcdonaldsbrunei/', icon: ['fab', 'facebook']},
        {href: 'https://www.instagram.com/mcdonaldsbrunei/?hl=en', icon: ['fab', 'instagram']},
        {href: 'https://twitter.com/mcdonaldsbrunei?lang=en', icon: ['fab', 'twitter']},
      ]
    }
  }
};
</script>

<style scoped>
.nav-link svg {
  width: 20px;
  height: 20px;
  color: #FFBC0D;
  transition: all 0.3s ease-in-out;
}

.nav-link:hover svg {
  transform: scale(1.3);
}

ul.nav.flex-column li {
  margin-bottom: 15px;
}

@media screen and (max-width: 768px) {
  .nav-link svg {
    width: 15px;
    height: 15px;
  }
}

@media screen and (max-width: 480px) {
  .nav-link svg {
    width: 15px;
    height: 15px;
  }
}

@media screen and (max-width: 287px) {
  .nav.flex-column {
    display: none;
  }
}
</style>